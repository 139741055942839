import {
    IFixedDownloadButtonProps,
    IHamburgerComponentProps,
    IHeaderComponentProps
} from "../../../lib/types/components";
import {publishWithUsType, sendPublishWithUsEvent} from "../../../lib/utils/constants/AnalyticsConstants";
import {
    GDC_HEADER_TYPE,
    GDC_WEB_URL,
    GET_APP_AF_URL,
    GET_APP_FB_URL,
    GET_APP_URL,
    HEADER_DOWNLOAD_BUTTON_TYPE
} from "../../../lib/utils/constants/GlobalConstants";
import {currentPageURL} from '../../../lib/utils/constants/PagesConstants';
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import GDCSubmitButton from "../FixedDownloadButton/GDCSubmitButton";
import HamburgerComponent from "../Hamburger/HamburgerComponent";
import LanguageToggleComponent from "../LanguageToggle/LanguageToggleComponent";
import React from "react";
import styles from "./HeaderComponentV2.module.css"
import useIsMobile from "../../../lib/customHooks/isMobile";
import {getLogoUrl, multipleClassName} from "../../../lib/utils/helpers/helper";

const HeaderComponentV2 = (props: IHeaderComponentProps) => {
    const {isMobileDevice} = useIsMobile()

    const {
        specialData,
        fixedDownloadButtonData,
        downloadLink,
        isApkDownloading,
        handleHamburgerClick,
        menuOpen,
        sendAnalytics,
        hideDownloadButton,
        setIsApkDownloading,
        setIsToastOpen,
        isUtmHotstar,
        languageToggleData,
        showToggle,
        locale,
        countrySwitcherText,
        abTestingData,
        appVersions,
        setAppsFlyerURL,
        headerType,
        hideSubmitButton,
        gdcFormUrl,
        gdcButtonText
    } = props;


    const hamburgerCompProps: IHamburgerComponentProps = {
        handleHamburgerClick: handleHamburgerClick,
        menuOpen: menuOpen
    }

    const hideHamburger: boolean = props.hideHamburger || (currentPageURL === GET_APP_URL || currentPageURL === GET_APP_AF_URL || currentPageURL === GET_APP_FB_URL);

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        apkUrl: downloadLink,
        isApkDownloading: isApkDownloading,
        type: HEADER_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        isUtmHotstar: isUtmHotstar,
        sendAnalytics: sendAnalytics,
        setIsApkDownloading: setIsApkDownloading,
        setIsToastOpen: setIsToastOpen,
        appVersions,
        abTestingData,
    }

    const toggleLanguageProps = {
        menuOpen: menuOpen,
        languageToggleData: languageToggleData,
        locale: locale,
        handleHamburgerClick: handleHamburgerClick
    }

    const isGetApp = currentPageURL === GET_APP_URL

    return (
        fixedDownloadButtonData ?
            <>
                <header className={styles.header}>
                    <div className={"container"}>
                        <nav className={multipleClassName("nav", styles.nav)}>
                            <div className={styles.logoContainer}>
                                <div className={styles.logo}>
                                    {
                                        (currentPageURL && currentPageURL.includes(GET_APP_URL)) ? (
                                            <CustomImageComponent
                                                layout={"fill"}
                                                objectFit={"contain"}
                                                src={getLogoUrl(locale)}
                                                alt="winzo games logo"
                                                priority={true}
                                            />
                                        ) : (
                                            headerType === GDC_HEADER_TYPE ?
                                                <a href={"https://console.winzogames.com/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src={getLogoUrl(locale)}
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </a>
                                                :
                                                <CustomLinkComponent href={"/"}>
                                                    <CustomImageComponent
                                                        layout={"fill"}
                                                        objectFit={"contain"}
                                                        src={getLogoUrl(locale)}
                                                        alt="winzo games logo"
                                                        priority={true}
                                                    />
                                                </CustomLinkComponent>
                                        )
                                    }
                                </div>
                                <div className={styles.languageToggle}>
                                    {showToggle && languageToggleData &&
                                        <LanguageToggleComponent {...toggleLanguageProps} />}
                                </div>
                            </div>


                            <div className={styles.navItemsContainer}>
                                <div className={styles.navItems}
                                     style={typeof isMobileDevice === "undefined" || isMobileDevice
                                         ? {display: `${headerType === GDC_HEADER_TYPE ? "block" : "none"}`}
                                         : {}}>
                                    <ul>
                                        {

                                            (!menuOpen && !isGetApp && specialData) && specialData.map((item, index) => {
                                                    if (item.dropdown) {
                                                        return (
                                                            <li key={"specialData_" + index}>
                                                                <div className={styles.dropdown} key={index}>
                                                                    <div className={styles.dropbtn}>{item.title}</div>
                                                                    <div className={styles.dropdownContent}>
                                                                        {item.dropdown.map((drop, i) => {
                                                                            return <a href={drop.link}
                                                                                      key={"item.dropdown_" + i}>{drop.title}</a>
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    if (item.special && !item.dropdown) {
                                                        if (item.isButtonType) {
                                                            return (
                                                                <a
                                                                    href={
                                                                        item.link
                                                                    }
                                                                >
                                                                    <button
                                                                        className={
                                                                            styles.season1
                                                                        }
                                                                    >
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </button>
                                                                </a>
                                                            );
                                                        }
                                                        return (
                                                            <li key={index} className={styles.greenBackground}>
                                                                {
                                                                    item.outside
                                                                        ?
                                                                        <a href={item.link} target="_blank" rel={"noreferrer"}
                                                                           onClick={() => {
                                                                               item.link === GDC_WEB_URL && sendPublishWithUsEvent(publishWithUsType.DESKTOP)
                                                                           }}>
                                                                            <span>{item.title}</span>
                                                                        </a>
                                                                        :
                                                                        <CustomLinkComponent
                                                                            href={item.link}
                                                                            mobStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                            desktopStyle={{
                                                                                textDecoration: "none"
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {item.title}
                                                                            </span>
                                                                        </CustomLinkComponent>
                                                                }
                                                            </li>
                                                        )

                                                    }
                                                    return <></>
                                                }
                                            )
                                        }
                                    </ul>
                                </div>
                                {
                                    hideDownloadButton
                                        ? <></>
                                        : <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
                                }
                                {headerType === GDC_HEADER_TYPE && !hideSubmitButton ? (
                                    <GDCSubmitButton
                                        text={gdcButtonText}
                                        url={gdcFormUrl}
                                        sendAnalytics={sendAnalytics}
                                        type={HEADER_DOWNLOAD_BUTTON_TYPE}
                                    />
                                ) : (
                                    <></>
                                )}
                                {
                                    !hideHamburger && <HamburgerComponent {...hamburgerCompProps} />
                                }
                            </div>
                        </nav>
                    </div>
                </header>
            </> : <></>
    )
}

export default HeaderComponentV2;